import ReactDOM from 'react-dom';   
import React,  { useEffect,  useState ,setIsLoaded , Component  } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import ReactRoundedImage from "react-rounded-image";
import {Card, Button, Container, Row, Col, CardGroup,  Image, Form, InputGroup, ListGroup} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faQuoteLeft,faFilePdf, faUserMd, faUserHardHat, faUser, faPhoneAlt, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import GoogleMapReact from 'google-map-react';
import YouTube from 'react-youtube';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import OurAchiver from './OurAchiver';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Modal from 'react-bootstrap/Modal';

class Home extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            items: [],
            isLoaded: false,
            quickName:'',
            quickMobile:'',
            quickEmail: '',
            qn:true,
            qm:true,
            qe:true,
            show: true,
        }

    }
   // const [show, setShow] = useState(false);

    handleClose = () =>{
        this.setState({
            show: false
        })  
    }
    handleShow = () => {
        this.setState({
            show: true
        }) 
    }

    handleInput = (event)=>{
        this.setState({
            [event.target.name]: event.target.value
        })  
    }
    componentDidMount() {
        localStorage.setItem('sID', null);
        fetch('https://dirasa.in/api/getAllNotice.php')
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json,
                    isLoaded: true, 
                })
            }).catch((err) => {
                console.log(err);
            });

    }

    qucikRegister=()=>{
        if(this.state.quickName !='' && this.state.quickMobile !='' && this.state.quickEmail !=''){
            console.log(this.state)
            
            const data = {
                name : this.state.quickName,
                mobile : this.state.quickMobile,
                email : this.state.quickEmail
    
            }
            axios 
                .post('https://dirasa.in/api/quickRegister.php',{data})
                .then(res =>{
                    console.log('res'+ res);
                    console.log(res.data);
                    if(res.data==1){
                        toast.success("Successfully Registered!");
                        this.setState({
                            quickName:' ' ,
                            quickMobile:' ',
                            quickEmail:' ',
                        })
                    }else{
                        toast.error("Something goes wrong!");
                    }

                });
        }else{
            if(!this.state.quickName)
                this.setState({
                    qn:false,
                    qm:true,
                    qe:true
                })
            if(!this.state.quickMobile)
                this.setState({
                    qn:true,
                    qm:false,
                    qe:true
                })
            if(!this.state.quickEmail)
                this.setState({
                    qn:true,
                    qm:true,
                    qe:false
                })
            toast.error("Plz enter all fields!");
        }
    }


render(){
   
    const { isLoaded, items } = this.state;
    var c=0;
    const path = "img/notice/";
    return (
        <>
         <ToastContainer />
         {/* <Modal show={this.state.show} onHide={this.handleClose}>
            
            <Modal.Body>
                <img src="img/popup.jpg" width="100%" />
                <br />
                <div style={{padding:"10px"}}><a className='btna' href="RegistrationNew" >Click For Registration</a></div>
                </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
                Close
            </Button>
           
            </Modal.Footer>
        </Modal> */}
        
        <div style={{marginTop: "100px"}} className="slideMargin">
            <Carousel>
                <Carousel.Item>
                    
                        <img
                        className="d-block w-100 slideMain"
                        src="img/slide44.jpg"
                        alt="First slide"
                        style={{height: "75vh"}}
                        />
                    
                    <Carousel.Caption>
                    {/* <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <a href= "https://dirasa.collegedoors.com">
                    <img
                    className="d-block w-100 slideMain"
                    src="img/slide5.jpg"
                    alt="First slide"
                    style={{height: "60vh"}}
                    />
                    </a>
                    <Carousel.Caption>
                    {/* <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100 slideMain"
                    src="img/slide0.jpg"
                    alt="Third slide"
                    style={{height: "60vh"}}
                    />

                    <Carousel.Caption>
                    {/* <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                 <Carousel.Item>
                    <img
                    className="d-block w-100 slideMain"
                    src="img/slide4.jpg"
                    alt="Third slide"
                    style={{height: "60vh"}}
                    />

                    <Carousel.Caption>
                    {/* <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </Carousel.Item> 
                </Carousel>
        </div>
        <div className="text-center" style={{padding: "15px",fontSize: "22px",backgroundColor: "#ffe007"}}>
        {/* <a href="RegistrationNew"><blink style={{color: "red",fontWeight: "600"}}>Click Here To Show Online Form For Admission Test 2023</blink></a> */}
        <a href="img/notice/Result_of_DSAT_2025-print.pdf"><span style={{color: "#d30707",fontWeight: "600",animation: "blink-animation 2s infinite"}}>Click Here to download Result Of Entrance Examination For Class Xl (Science)</span></a>
                <style>
                {`
                @keyframes blink-animation {
                    50% { opacity: 0; }
                }
                `}
                </style>
        </div>
        
        <div style={{backgroundRepeat: "no-repeat",    backgroundPosition: "center", backgroundSize: "cover" }}  className="mt-5">
                <div style={{backgroundColor: "#fcfcfc"}} > 
                <Container fluid>
                    <div >
                    
                        {/* <h1 className="text-center" style={{padding: "40px" , fontWeight: "700",color:"#000", fontSize:"3.5rem"}}>Ask to Our expert</h1>
                        <FontAwesomeIcon icon={["fal", "f8d3"]} /> */}
                        <div style={{marginTop:"15px"}}>
                            <Form style={{paddingBottom: "50px",paddingTop: "50px"}}>
                                    <Row  >
                                        <Col xs={12} md={12} lg={6} className="text-center">
                                        <h1 className="text-center"  style={{ fontWeight: "600",color:"#fd1f1f", fontSize:"2rem"}}>Results &nbsp; 2021</h1>

                                        <div id="youtubeVideo"  style={{ width: "500px",height: "281px",margin: "auto",padding:"10px"}}> 
                                        <iframe src="https://www.youtube.com/embed/d19TbODTrvM?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=1&controls=1&autoplay=1"  width="100%" height="100%"  frameborder="0" allowfullscreen></iframe>
                                        </div> 
                                        
                                        </Col>
                                        <Col xs={12} md={12} lg={6} style={{padding:"5px"}} >
                                            <h1 className="text-center"  style={{ fontWeight: "600",color:"#fd1f1f", fontSize:"2rem"}}>Quick Register</h1>
                                            <div>
                                            <div   style={{margin: "0 auto", width: "fit-content",boxShadow: "0px 0px 5px rgb(0 0 0 / 58%)",padding: "14px",borderRadius: "5px",marginTop:"20px"}}>
                                                <Form >
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Name" name="quickName" id="quickName" onChange={this.handleInput} value={this.state.quickName} required />
                                                        {!this.state.qn?<Form.Text style={{color:"red"}}>Plz Enter Your Name</Form.Text>:null}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Mobile No</Form.Label>
                                                        <Form.Control type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 10)}  placeholder="Enter Mobile No" name="quickMobile" id="quickMobile" onChange={this.handleInput} value={this.state.quickMobile} required />
                                                        {!this.state.qm?<Form.Text style={{color:"red"}}>Plz Enter Your Mobile No</Form.Text>:null}
                                                       
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter Email" name="quickEmail" id="quickEmail" onChange={this.handleInput} value={this.state.quickEmail} />
                                                        {!this.state.qe?<Form.Text style={{color:"red"}}>Plz Enter Your Email</Form.Text>:null}
                                                       
                                                    </Form.Group>

                                                    
                                                    <Button variant="danger" onClick={this.qucikRegister}>Submit</Button> 
                                                   
                                                    </Form>
                                            </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Form>
                        </div>
                    </div>
                </Container>
                </div>
            </div>
        <div>
           <h1  className="text-center" id="divHeader" style={{color: "#fd1f1f !important"}}> WHY DIRASA</h1>
           <br />
           {/* <Container>
                <Row>
                    <Col sm={12} md={4} lg={4}  >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="/img/card3.jpg" />
                            <Card.Body>
                                <Card.Title>CLASSROOM</Card.Title>
                                <Card.Text>
                                We offer specialized teaching and a competing atmosphere to the Pre-Medical aspirants through our excellent curriculum and adequate classroom programmes. Our distinguished team of highly qualified and competent faculties gives quality education to the students for them to determine their own strengths and work along them. All students are provided with personal attention and by being a part of the year-long classroom programme, students are able to perform to the best of their ability.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} lg={4} >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="/img/card1.jpg" />
                            <Card.Body>
                                <Card.Title>REGULAR EXAM</Card.Title>
                                <Card.Text>
                                We conduct periodic test series to judge and monitor the performance and understanding levels of students. Efforts are made to create real exam like scenario, so as to get the students acclimatized to exam situation.
                                    i) Weekly Test
                                    ii) Monthly Test 
                                    iii) 10 full length Test
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} lg={4} >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="/img/card2.jpg" />
                            <Card.Body>
                                <Card.Title>SPR <br /> (STUDENTS PERFORMING REPORT)</Card.Title>
                                <Card.Text>
                                Student’s academic performance in each test is analyzed on various parameters at macro & micro level and regular feed to parents about their child's performance.
                                    i)Analysis of weakness
                                    ii)Weekly report
                                    iii)counselling
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    
                </Row>
            </Container> */}
            <Container>
            <CardGroup>
                <Card className="container-review">
                    <Card.Img variant="top" src="/img/card3.jpg" />
                    <Card.Body>
                    <Card.Title>CLASSROOM</Card.Title>
                    <Card.Text>
                    We offer specialized teaching and a competing atmosphere to the Pre-Medical aspirants through our excellent curriculum and adequate classroom programmes. All students are provided with personal attention and by being a part of the year-long classroom programme, students are able to perform to the best of their ability.
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                    <small className="text-muted">Last updated 3 mins ago</small>
                    </Card.Footer>
                </Card>
                <Card className="container-review">
                    <Card.Img variant="top" src="/img/card1.jpg" />
                    <Card.Body>
                    <Card.Title>REGULAR EXAM</Card.Title>
                    <Card.Text>
                    We conduct periodic test series to judge and monitor the performance and understanding levels of students. Efforts are made to create real exam like scenario, so as to get the students acclimatized to exam situation.
                                    i) Weekly Test
                                    ii) Monthly Test 
                                    iii) 10 full length Test
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                    <small className="text-muted">Last updated 3 mins ago</small>
                    </Card.Footer>
                </Card>
                <Card className="container-review">
                    <Card.Img variant="top" src="/img/card2.jpg" />
                    <Card.Body>
                    <Card.Title>SPR <br /> (STUDENTS PERFORMING REPORT)</Card.Title>
                    <Card.Text>
                    Student’s academic performance in each test is analyzed on various parameters at macro & micro level and regular feedback to parents about their child's performance.
                                    i)Analysis of weakness
                                    ii)Weekly report
                                    iii)counselling
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                    <small className="text-muted">Last updated 3 mins ago</small>
                    </Card.Footer>
                </Card>
            </CardGroup>
            </Container>
                

            <div style={{backgroundRepeat: "no-repeat",    backgroundPosition: "center", backgroundSize: "cover" }}  className="mt-5">
                <div style={{backgroundColor: "#f7f7f7"}} > 
                <Container style={{paddindBottom:"70px", padding:"20px"}}>
                    <div >
                        <h5 className="text-center" style={{ paddingLeft: "40px",paddingRight: "40px",paddingBottom: "40px", fontWeight: "700",color:"#bb1414", fontSize:"3.5rem"}}>Coming Soon</h5>
                        <div style={{marginTop:"15px"}} >
                        <h4 style={{fontSize: "19px", lineHeight: "34px", margin:"0px"}}>NEET, JEE &amp; Foundation Classroom &amp; Hybrid Courses</h4>   
                        <div>
                        <div>
                            <Row>
                                <Col md={1} className="text-right">
                                    1.
                                </Col>
                                <Col md={2}>
                                    <b>Eligibility: </b>
                                </Col>
                                <Col md={9}>
                                    Class 7th - 12th Studying Students for admission in courses starting <text style={{color:"red"}}>coming soon</text>.
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1} className="text-right">
                                    2.
                                </Col>
                                <Col md={2}>
                                    <b>Timings:</b>
                                </Col>
                                <Col md={9}>
                                     Any time between 10 AM and 6 PM on the selected exam day
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1} className="text-right">
                                    3.
                                </Col>
                                <Col md={2}>
                                    <b>Duration:</b>
                                </Col>
                                <Col md={9}>
                                    Online Exam Duration: 60 mins.
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1} className="text-right">
                                    4.
                                </Col>
                                <Col md={2}>
                                    <b>Registration Fee:</b>
                                </Col>
                                <Col md={9}>
                                    200/-
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1} className="text-right">
                                    4.
                                </Col>
                                <Col md={2}>
                                    <b>Benefits: </b>
                                </Col>
                                <Col md={9}>
                                    *Up to 90% Scholarship on Tuition Fee & Classroom Service Fee**.
                                </Col>
                            </Row>
                        </div>

                        <h4 style={{fontSize: "24px", lineHeight: "34px", margin:"20px 0px"}}><span style={{color:"rgb(191 21 12)", fontSize:"24px",fontWeight: "700", animation: "blink 1s linear infinite"}}>Online Test Series<a href="https://dirasa.collegedoors.com"><blink style={{marginLeft:"20px"}}>Students Login</blink></a></span></h4>
                         </div>                           
                        </div>
                    </div>
                </Container >
                </div>
            </div>

            {/* <div style={{backgroundImage: "url('./img/resultBack.jpg')" }} className="mt-5">
                <div style={{backgroundColor: "rgb(216 215 211 / 92%)"}}> 
                <Container>
                    <div >
                        <h1 className="text-center" style={{padding: "40px", fontWeight: "700", color: "rgb(82 81 77)", fontSize: "45px", fontFamily: "'Open Sans',sans-serif!important"}}>Our Results</h1>
                        <div style={{marginTop:"15px"}}>
                            <Row style={{paddingBottom: "50px"}}>
                                <Col xs={12} md={6} className="text-center">
                                    <h3 style={{color:"#827879", fontSize:"2rem",fontWeight: "700"}}>MEDICAL</h3>
                                    <FontAwesomeIcon icon={faUserMd}  size="6x" color="#827879" />
                                    <br />
                                    <span className="number_0">5,500</span>
                                    <span class="number_append">+</span>
                                </Col>
                                <Col xs={12} md={6} className="text-center">
                                    <h3 style={{color:"#827879", fontSize:"2rem",fontWeight: "700"}}>ENGINEERING</h3>
                                    <FontAwesomeIcon icon={faUserMd}  size="6x" color="#827879" />
                                    <br />
                                    <span className="number_0">55,500</span>
                                    <span class="number_append">+</span>
                                </Col>
                           </Row>
                        </div>
                    </div>
                </Container>
                </div>
            </div> */}
            {/* <div style={{backgroundColor:"#f7f7f7", paddingTop: "20px", paddingBottom: "50px" }} className="mt-5">
                <Container>
                    <div >
                        <h1 className="text-center">Our Faculty</h1>
                        <div style={{marginTop:"115px"}}>
                        <CardGroup>
                            <Col xs={6} md={4}>
                                <Card className="text-center shadow" style={{    alignItems: "center"}}>
                                    <Image className="profileImg shadow" src="https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cg_face%2Cq_auto:good%2Cw_300/MTU0NjQzOTk4OTQ4OTkyMzQy/ansel-elgort-poses-for-a-portrait-during-the-baby-driver-premiere-2017-sxsw-conference-and-festivals-on-march-11-2017-in-austin-texas-photo-by-matt-winkelmeyer_getty-imagesfor-sxsw-square.jpg" roundedCircle width="150" />
                                    <Card.Body style={{marginTop: "-75px"}}>
                                    <Card.Title>Card title</Card.Title>
                                    <Card.Text>
                                        This card has supporting text below as a natural lead-in to additional
                                        content.{' '}
                                    </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                    <small className="text-muted">Last updated 3 mins ago</small>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col xs={6} md={4}>
                                <Card className="text-center shadow" style={{    alignItems: "center"}}>
                                    <Image className="profileImg shadow" src="https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cg_face%2Cq_auto:good%2Cw_300/MTU0NjQzOTk4OTQ4OTkyMzQy/ansel-elgort-poses-for-a-portrait-during-the-baby-driver-premiere-2017-sxsw-conference-and-festivals-on-march-11-2017-in-austin-texas-photo-by-matt-winkelmeyer_getty-imagesfor-sxsw-square.jpg" roundedCircle width="150" />
                                    <Card.Body style={{marginTop: "-75px"}}>
                                    <Card.Title>Card title</Card.Title>
                                    <Card.Text>
                                        This card has supporting text below as a natural lead-in to additional
                                        content.{' '}
                                    </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                    <small className="text-muted">Last updated 3 mins ago</small>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col xs={6} md={4}>
                                <Card className="text-center shadow" style={{    alignItems: "center"}}>
                                    <Image className="profileImg shadow" src="https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cg_face%2Cq_auto:good%2Cw_300/MTU0NjQzOTk4OTQ4OTkyMzQy/ansel-elgort-poses-for-a-portrait-during-the-baby-driver-premiere-2017-sxsw-conference-and-festivals-on-march-11-2017-in-austin-texas-photo-by-matt-winkelmeyer_getty-imagesfor-sxsw-square.jpg" roundedCircle width="150" />
                                    <Card.Body style={{marginTop: "-75px"}}>
                                    <Card.Title>Card title</Card.Title>
                                    <Card.Text>
                                        This card has supporting text below as a natural lead-in to additional
                                        content.{' '}
                                    </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                    <small className="text-muted">Last updated 3 mins ago</small>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            
                            
                        </CardGroup>
                        </div>
                    </div>
                </Container>
            </div> */}
            <div className="section">
            <Container>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className="text-center">News / Event</Card.Header>
                            <Card.Body>
                                
                                <div>
                                {items.map(item => (
                                        <Row style={{marginTop:"20px"}}>
                                            <Col md={2} sm={2} xs={2}>
                                                <div className="noticeNo">
                                                    <span>{++c}</span>
                                                </div>
                                            </Col>
                                            {/* <Col md={2}  className="d-xs-none d-md-none">
                                            <div>
                                                <span className="noticeDay">{item.day}</span> <br />
                                                <span>{item.dateV}</span> 
                                            </div>
                                            </Col> */}
                                            
                                            <Col md={10} xs={10}>
                                            <div style={{borderBottom: "1px solid #c1c1c1"}}>
                                                <span style={{fontSize: "19px", fontWeight: "600"}}>
                                                    {item.noticeTitle}
                                                </span>
                                            </div>
                                            <div>
                                                <FontAwesomeIcon icon={faFilePdf} className="pdfIcon" />
                                                <span style={{fontSize: "13px",position: "relative", top: "-10px",  marginLeft: "3px"}}>
                                                    <a href={path + item.file }>Clicl Here</a>
                                                    
                                                     &nbsp; to view details
                                                     </span>
                                            </div>
                                            </Col>
                                        </Row>
                                        
                                           
                                        
                                    ))}
                                </div>
                               
       
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div style={{backgroundRepeat: "no-repeat",    backgroundPosition: "center", backgroundSize: "cover" }}  className="mt-5">
                <div style={{backgroundColor: "#fcfcfc"}} > 
                <Container >
                    <div >
                        <h1 className="text-center" style={{padding: "40px" , fontWeight: "700",color:"#000", fontSize:"3rem"}}>Our Achivers</h1>
                        <div style={{marginTop:"15px"}}>
                        <OurAchiver />
                        </div>
                    </div>
                </Container>
                </div>
            </div>
             <Container className="text-center container-review">
                <div className="header-review text-muted"> <h4>What people are saying about <span style={{color:"red"}}>dirasa</span> </h4></div>
                <Row>
                    <div className="card-review col-md-4 col-11">
                        <div className="card-content">
                            <div className="card-body p-0">
                                <div className="profile mb-4 mt-3"> <img src="img/rev1.jpg" /> </div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted"> <FontAwesomeIcon icon={faQuoteLeft} className="quoteLeft" />  I have been with this coaching institute for half and one year, faculties are too good, students were responsive and having so much potential to crack Neet (UG), test series are best among all and are national standard and patterned ! From a peripheral District’s perspective it is definitely the best Institute to learn ! <FontAwesomeIcon icon={faQuoteLeft} className="quoteLeft fa-flip-horizontal" /> </small> </p>
                                </div>
                                <div className="card-body company"> <i className="fab fa-ebay fa-2x"></i> <small className="intro text-muted">Dr. Biswajit Ghosh, MBBS</small> </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-review col-md-4 col-11 second-review">
                        <div className="card-content">
                            <div className="card-body p-0">
                                <div className="profile mb-4 mt-3"> <img src="img/rev2.jpg" /> </div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted"> <FontAwesomeIcon icon={faQuoteLeft} className="quoteLeft" /> One of the best platforms for students of Malda to become competent enough to live their dreams of becoming a doctor.
 And last but not the least the discipline of the institute in taking regular Class Tests, Monthly Mock Tests trains a student for a long race of Competitive Examinations. <FontAwesomeIcon icon={faQuoteLeft} className="quoteLeft fa-flip-horizontal" /> </small> </p>
                                </div>
                                <div className="card-body company"> <i className="fab fa-ebay fa-2x"></i> <small className="intro text-muted">Dr. Mohidur Rahaman, MBBS</small> </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-review col-md-4 col-11 third-review">
                        <div className="card-content">
                            <div className="card-body p-0">
                                <div className="profile mb-4 mt-3"> <img src="img/rev3.jpg" /> </div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted"> <FontAwesomeIcon icon={faQuoteLeft} className="quoteLeft" /> Best teachers from professional fields guides students so that they can bring out the best from them. <FontAwesomeIcon icon={faQuoteLeft} className="quoteLeft fa-flip-horizontal" /> </small> </p>
                                </div>
                                <div className="card-body company"> <i className="fab fa-ebay fa-2x"></i> <small className="intro text-muted">Prof. Ataur Rahaman</small> </div>
                            </div>
                        </div>
                    </div>
                    
                </Row>
            </Container> 
            
            {/* <div style={{backgroundRepeat: "no-repeat",    backgroundPosition: "center", backgroundSize: "cover" }}  className="mt-5">
                <div style={{backgroundColor: "#f1f1f1"}} > 
                <Container >
                    <div >
                        <h1 className="text-center" style={{padding: "40px" , fontWeight: "700",color:"#000", fontSize:"3.5rem"}}>Ask to Our expert</h1>
                        <FontAwesomeIcon icon={["fal", "f8d3"]} />
                        <div style={{marginTop:"15px"}}>
                            <Form style={{paddingBottom: "50px"}}>
                                    <Row  >
                                        <Col xs={12} md={4} >
                                        <Form.Group controlId="formFullName">
                                            <Form.Control type="text" placeholder="Full Name" />
                                            <Form.Text className="text-muted">
                                            Enter full name.
                                            </Form.Text>
                                        </Form.Group>
                                        </Col>
                                        <Col xs={12} md={4}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control type="email" placeholder="Enter email" />
                                            <Form.Text className="text-muted">
                                            Email Id.
                                            </Form.Text>
                                        </Form.Group>
                                        </Col>
                                        <Col xs={12} md={4}>
                                        <Form.Group controlId="formBasicMobile">
                                            <Form.Control type="text" placeholder="Mobile No." />
                                            <Form.Text className="text-muted">
                                            Mobile No.
                                            </Form.Text>
                                        </Form.Group>
                                        </Col>

                                       
                                       
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} className="text-center">
                                            <Button variant="primary" type="submit" style={{    backgroundColor: "#3789da"}}>
                                            &nbsp;&nbsp;REQUEST A CALL BACK&nbsp;&nbsp;&nbsp;
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                        </div>
                    </div>
                </Container>
                </div>
            </div> */}
            <Container fluid style={{padding: "35px"}}>
                <Row>
                <div style={{ height: '57vh', width: '100%' }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.6982181246926!2d88.13873601537843!3d25.010368445391645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fadd1c40f3201b%3A0x77be481d95176704!2sDirasa%20l%20NEET!5e0!3m2!1sen!2sin!4v1614593580887!5m2!1sen!2sin" width="100%" height="520" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
                </div>
                </Row>
            </Container >
            </div>
        </div>
        
    </>        
    )
}

};
export default Home
